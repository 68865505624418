import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import { CFlex, DeleteIcon, DText, IconWrapper, UpdateIcon } from "../../../styles/components/common/common-styles";
import DetailMiniView from "../details/DetailMiniView";
import { formatDate } from "../../../core/utils/common";
import { useMainContext } from "../../../core/contexts/main";
import { Link, useNavigate } from "react-router-dom";
import Text from "../../../core/utils/Text";
import { ActionWrapper } from "../../../styles/components/sim/sim-styles";
import { useRunNormalizer, useStopNormalizer, useDeleteNormalizer } from "../../../core/services/react-query/normalizer";
import ModalLayout from "../../layouts/main/ModalLayout";
import DeleteModal from "../../modals/DeleteModal";
import { useState } from "react";
import { BooleanIcon } from "../../common/utils/BoleanIcon";
import PcViewer from "../../common/utils/PcViewer";

export const statusMap = {
    inactive: { desc: 'normalizer-inactive', color: 'gray' },
    progress: { desc: 'normalizer-progress', color: '#42B2F1' },
    paused: { desc: 'normalizer-paused', color: 'orange' },
    done: { desc: 'normalizer-done', color: 'green' },
    error: { desc: 'normalizer-error', color: 'red' },
}

const NormalizerList = ({ normalizers, cs, sender = false }) => {

    const { main: { lang } } = useMainContext()
    const navigate = useNavigate()

    const [selectedNormalizer, setSelectedNormalizer] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const openDeleteModal = () => setDeleteModalOpen(true)
    const closeDeleteModal = () => setDeleteModalOpen(false)

    const { mutate: runNormalizer } = useRunNormalizer()
    const { mutate: stopNormalizer } = useStopNormalizer()
    const { mutate: deleteNormalizer } = useDeleteNormalizer()

    const NormalizerAction = (props) => {
        const { normalizer } = props
        if (normalizer.status === 'done') return <></>

        const active = normalizer.active
        const onActionClicked = () => {

            const payload = {
                id: normalizer._id
            }
            active ? stopNormalizer(payload) : runNormalizer(payload)
        }

        return (
            <ActionWrapper active={active} {...props} onClick={onActionClicked}>
                <Text tid={active ? 'stop' : 'start'} />
            </ActionWrapper>
        )
    }

    const onUpdateClicked = (normalizer) => {
        if (sender) navigate(`/sender/${normalizer._id}?type=edit`)
        else navigate(`/normalizers/${normalizer._id}?type=edit`)
    }

    const onDeleteClicked = (normalizer) => {
        setSelectedNormalizer(normalizer)
        openDeleteModal()
    }

    const onDeleteSubmitted = () => {
        setSelectedNormalizer(null)
        closeDeleteModal()
        deleteNormalizer(selectedNormalizer._id)
    }

    return (
        <>
            {normalizers?.data?.map((normalizer, idx) => {

                let totalPc = 0
                for (const group of normalizer.groups) {
                    for (const message of normalizer.messages) {
                        for (const date of message?.static) {
                            totalPc += 1
                        }
                    }
                }

                return (
                    <Row
                        cs={cs}
                        key={normalizer.createdAt}
                        action={normalizer.status}
                    >
                        <Column style={{ alignItems: 'center' }}>
                            <div>
                                {idx + 1}
                            </div>
                        </Column>
                        <Column>
                            {normalizer.name}
                        </Column>
                        <Column>
                            <DetailMiniView
                                margin={'0'}
                                chat={{
                                    title: normalizer.sim_id?.username
                                }}
                                link={'sims'}
                                idx={idx}
                                _id={normalizer.sim_id?._id}
                            />
                        </Column>
                        {sender ?
                            <Column>
                                <PcViewer
                                    total={totalPc}
                                    filled={normalizer.ids.length}
                                />
                            </Column>
                            : null}
                        <Column>
                            <DateBadge>
                                <CFlex>
                                    <DText primary>
                                        {formatDate(normalizer.createdAt, 'date', lang)}
                                    </DText>
                                    <DText primary>
                                        {formatDate(normalizer.createdAt, 'time', lang)}
                                    </DText>
                                </CFlex>
                            </DateBadge>
                        </Column>
                        <Column>
                            <DText color={statusMap[normalizer.status]?.color}>
                                {normalizer.status}
                            </DText>
                        </Column>

                        <Column more>
                            {normalizer.done ?
                                <BooleanIcon bool />
                                :
                                <NormalizerAction normalizer={normalizer} />
                            }
                            <IconWrapper
                                onClick={() => onUpdateClicked(normalizer)}
                            >
                                <UpdateIcon size={22} />
                            </IconWrapper>
                            <IconWrapper
                                onClick={() => onDeleteClicked(normalizer)}
                            >
                                <DeleteIcon size={22} />
                            </IconWrapper>

                            <Link to={`/${sender ? 'sender' : 'normalizers'}/${normalizer._id}`}>
                                <IconWrapper>
                                    {lang === 'en' ?
                                        <DetailsLtr size={24} />
                                        :
                                        <DetailsRtl size={24} />
                                    }
                                </IconWrapper>
                            </Link>
                        </Column>
                    </Row>
                )
            })}

            <ModalLayout
                open={deleteModalOpen}
                onClose={closeDeleteModal}
                width='600px'
            >
                <DeleteModal
                    type={'normalizer'}
                    onSubmit={onDeleteSubmitted}
                    onCancel={closeDeleteModal}
                    extra={[
                        { title: 'groups', count: selectedNormalizer?.groups.length }
                    ]}
                />
            </ModalLayout>
        </>
    )
}


export default NormalizerList
